
import ApiService from "@/core/services/ApiService";
import { defineComponent, ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "VerifyEmail",
  components: {},
  setup() {
    const router = useRouter();
    const route = useRoute();

    const verifyURL = ref("");

    onMounted(() => {
      // if (!verifyURL.value) {
      //   router.push({ name: "signIn" });
      // }

      verifyURL.value = route.query.token as string;

      ApiService.get(atob(verifyURL.value))
        .then(({ data }) => {
          if (data.status == false) {
            Swal.fire({
              text: data.message,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          } else {
            Swal.fire({
              text: data.message,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              router.push({
                name: "signIn",
                query: { email_verified: "yes" },
              });
            });
          }
        })
        .catch(({ response }) => {
          Swal.fire({
            text: response.data.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    });

    return {};
  },
});
